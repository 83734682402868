/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import weLogo from '../resources/images/we-logo.png'
import raritetLogo from '../resources/images/raritet-logo.png'
import { HeaderButton } from './Button'
import routes from '../routes'
import useStores from '../hooks/useStores'
import { observer } from 'mobx-react'
import { BodyScrollController } from './common/theme'

const contactEmail = 'support@raritet.io'
const headerBreakepoint = '(max-width: 1220px)'

const BurgerButton = styled.button`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
`

const BurgerIcon = styled.div<{ active: boolean }>`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 16px;
  z-index: 15;

  :before,
  :after,
  div {
    background: #fff;
    content: "";
    display: block;
    height: 2px;
    border-radius: 3px;
    margin: 5px 0;
    transition: 0.5s;
  }

  ${props => props.active && `
    :before {
    transform: translateY(7px) rotate(135deg);
    }

    :after {
      transform: translateY(-7px) rotate(-135deg);
    }

    div {
      transform: scale(0);
    }
  `}
`

const LogoWrapper = styled.div`
  width: 100px;
  height: 51px;
  background-image: url(${weLogo});
  background-size: cover;
  
  @media ${headerBreakepoint} {
    width: 70px;
    height: 36px;
  } 
`

const NavBar = styled.div`
  position: relative;
  z-index: 10;
  .mobile-navigation {
    display: none;
  }

  @media ${headerBreakepoint} {
    .navigation {
      display: none;
    }

    .mobile-navigation {
      display: block;
    }
  } 
`

const MobileNavigation = styled.div`
  @media ${headerBreakepoint} {
    width: 100vw;
    background-color: ${props => `${props.theme.colors.black}`};
    position: fixed;
    top: 0;
    justify-content: space-between;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.3);
    z-index: 20;
    &.show {
      height: 100%;
      overflow-y: scroll;
    }
  } 
`

const MobileInner = styled.div`
  height: 0vh;
  position: absolute;
  background-color: ${props => `${props.theme.colors.black}`};
  top: -100vh;
  transition: top 0.4s ease, visibility 0.4s ease;
  visibility: hidden;

  &.show {
    visibility: visible;
    top: 90px;
    height: calc(100vh - 90px);
  }

  &.hide {
    visibility: hidden;
    height: 100vh;
    top: -100vh;
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => `${props.theme.colors.black}`};
  height: ${props => `${props.theme.headerHeight}px`};
  padding: 0 40px;  
  position: relative;
  z-index: 20;

  @media ${headerBreakepoint} {
    padding: 0 20px;
    height: ${props => `${props.theme.mobileHeaderHeight}px`};
  } 
`

const Ul = styled.ul`
  display: flex;
  width: 50%;
  justify-content: center;

  @media ${headerBreakepoint} {
      flex-direction: column;
      padding: 20px 0;
      justify-content: start;
      width: 100vw;
  } 
`

const NavMenuItem = styled(NavLink)<{ selected?: boolean }>`
  margin: 0 1.5rem;
  &.active {
    border-bottom: ${props => `1px solid ${props.theme.colors.green}`}; 
    padding-bottom: .2rem;
  }

  @media (max-width: 1400px) {
    margin: 0 .8rem;
  } 

  @media ${headerBreakepoint} {
    width: fit-content;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 3rem;
  } 
`
const Account = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2px;

    @media ${headerBreakepoint} and (orientation: portrait) {
      width: 100%;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    } 

    @media ${headerBreakepoint} and (orientation: landscape) {
      width: 100%;
      align-items: center;
      justify-content: center;
      padding-bottom: 30px;
    } 
`

const LogoutButton = styled.button`
  color: red;
  cursor: pointer;
`

const MenuTitle = styled.h1`
  font-size: 32px;
`

const AddressWrapper = styled.div`
  @media ${headerBreakepoint} {
    font-size: 12px;
  } 
`

const LogoWrap = styled.div`
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    font-size: 14px;
    font-weight: 500;
    margin-top: .8rem;
    }

  @media ${headerBreakepoint} {
    a {
    width: fit-content;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 3rem;
    }
  } 

  .mobile {
    position: relative;
    top: 49px;
  }
`

type NavLinksProps = {
  onRootClick?: () => void,
}

const NavLinks = observer(({ onRootClick }: NavLinksProps) => {
  const { authStore, keysStore } = useStores()
  const { isLoggedIn } = authStore
  const { address } = keysStore
  const navigate = useNavigate()

  const onClickLogout = () => {
    if (onRootClick) {
      onRootClick()
    }
    BodyScrollController.scroll()
    authStore.logout()
    navigate('/')
  }

  const onClickLogin = () => {
    BodyScrollController.scroll()
    navigate(routes.signIn)
  }

  return <>
    <Ul>
      <NavMenuItem to={`/${routes.root}`} onClick={onRootClick}>Главная</NavMenuItem>
      {/* {roles.includes('nft_admin') && <NavMenuItem to={`/${routes.create}`}>Cоздать</NavMenuItem>} */}
      {isLoggedIn && <NavMenuItem to={`/${routes.myCollection}`}>Мои NFT</NavMenuItem>}
      <NavMenuItem to={`/${routes.howItWorks}`}>Как это работает?</NavMenuItem>
      {isLoggedIn && <NavMenuItem to={`/${routes.qrCode}`}>Получение по промо коду</NavMenuItem>}
    </Ul>
    <Account>
      {isLoggedIn && address ?
        <>
          <AddressWrapper>{address}</AddressWrapper>
          <LogoutButton onClick={onClickLogout}>выйти</LogoutButton>
        </>
        :
        <HeaderButton onClick={onClickLogin}>Вход и регистрация</HeaderButton>
      }
    </Account>

  </>
})

const MailLink = ({ text }: {text: string}) => <a href={`mailto:${contactEmail}`}>{text}</a>

const Menu = () => {
  return <Nav className='navigation'>
    <LogoWrap>
      <a href='https://raritet.io/' target='_blank'><LogoWrapper /></a>
      <MailLink text={contactEmail} />
    </LogoWrap>
    <NavLinks />
  </Nav>
}

const MobileMenu = () => {
  const navLinksRef = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()
  const [isMenuOpen, setMenuOpen] = useState(false)

  const onOutsideClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (navLinksRef.current === e.target) {
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    setMenuOpen(false)
  }, [pathname])

  useEffect(() => {
    if (isMenuOpen) {
      BodyScrollController.noScroll()
      return
    }
    BodyScrollController.scroll()
  }, [isMenuOpen])

  return <MobileNavigation className={'mobile-navigation ' + (isMenuOpen ? 'show' : 'hide')}>
    <Nav>
      {isMenuOpen ?
        <MenuTitle>Меню</MenuTitle> :
        <a href='https://raritet.io/' target='_blank'><LogoWrapper /></a>
      }
      <BurgerButton onClick={() => setMenuOpen((value) => !value)}>
        <BurgerIcon active={isMenuOpen}><div /></BurgerIcon>
      </BurgerButton>
    </Nav>
    <MobileInner ref={navLinksRef} onClick={onOutsideClick} className={isMenuOpen ? 'show' : 'hide'}>
      <NavLinks onRootClick={() => setMenuOpen(false)} />
      <LogoWrap><MailLink text='Связаться с нами' /></LogoWrap>
    </MobileInner>
  </MobileNavigation>
}

export const Header = () => {
  return <NavBar>
    <Menu />
    <MobileMenu />
  </NavBar>
}
