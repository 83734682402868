import { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Block } from '../../../components/Block'
import { PrimaryButton } from '../../../components/Button'
import { PageWrapper, PageTitle } from '../../../components/common/styles'
import { devices } from '../../../components/common/theme'
import { Input } from '../../../components/Input'
import ModalService from '../../../components/modal/modal-service'
import { Notification } from '../../../components/Notifications'
import useStores from '../../../hooks/useStores'
import { Success } from '../modals/SuccessModal'
import { qrCodeLocalStorageKey } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import routes from '../../../routes'

const InnerWrapper = styled.div`
  width: 45%;

  @media ${devices.tabletAndMobile} {
    width: 100%;
  }
`

const Description = styled.div`
  font-size: 16px;
  text-align: center;
`

export const QrCode = () => {
  const { authStore, api, keysStore } = useStores()
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const code = localStorage.getItem(qrCodeLocalStorageKey)
    if (code) {
      setCode(code)
    }

    return () => localStorage.removeItem(qrCodeLocalStorageKey)
  }, [])

  const navToMyCollection = () => {
    navigate(`/${routes.myCollection}`, { state: { fromQrCode: true } })
  }

  const claimToken = async () => {
    if (!authStore.isLoggedIn) {
      throw new Error('Not logged in')
    }
    setLoading(true)
    try {
      await api.claimToken(code.trim(), keysStore.address)

      ModalService.open(({ close }) => (
        <Success
          text='Запрос на получение NFT одобрен. Его можно будет найти в вашей коллекции через несколько минут'
          close={close}
          buttonsBlock={<PrimaryButton onClick={navToMyCollection}>Перейти в мою коллекцию</PrimaryButton>}
        />
      ))
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        toast(<Notification isError title={'Ошибка'} message={'Промокод не найден'} />, {
          hideProgressBar: true,
        })
      }
    } finally {
      setCode('')
    }

    setLoading(false)
  }

  return <PageWrapper>
    <Block marginTop={100} />
    <PageTitle>Получение по промо-коду</PageTitle>
    <InnerWrapper>
      <Description>
      Если вы получили промо-код на получение бесплатного NFT, вы можете ввести его здесь
      </Description>
      <Block marginTop={'15%'} />
      <Input
        label='Ваш промо-код'
        placeholder='Введите промо-код'
        value={code}
        onChange={(e: any) => setCode(e.target.value)}
      />
    </InnerWrapper>
    <PrimaryButton onClick={claimToken} disabled={loading || !code.trim()}>Получить</PrimaryButton>

  </PageWrapper>
}
